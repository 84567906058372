





































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { validationMixin } from "vuelidate";
import { Component } from "vue-property-decorator";
import { required, minLength } from "vuelidate/lib/validators";

import { CreateSeminarRequest } from "@/core/models";

@Component({
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      minLength: minLength(2),
    },
  },
})
export default class SeminarsAdmin extends Vue {
  @Getter("seminars/adding") adding!: boolean;
  @Action("displaySnackbar") displaySnackbar!: (s: string) => void;
  @Action("seminars/create")
  createSeminar!: (d: CreateSeminarRequest) => Promise<void>;

  addDialog = false;
  name = "";
  description = "";
  error: any = null;
  logo: File | null = null;
  preview: string | null = null;

  get nameErrors(): any[] {
    const errors: any[] = [];
    if (!this.$v.name!.$dirty) return errors;
    !this.$v.name!.required && errors.push(this.$t("Name is required"));
    !this.$v.name!.minLength &&
      errors.push(this.$t("Name must be at least one character in length"));
    return errors;
  }

  handleFileChanged(f: File | null) {
    if (!f) this.preview = null;
    else {
      const reader = new FileReader();
      reader.onload = _ => {
        this.preview = reader.result as string;
      };
      reader.readAsDataURL(f);
    }
  }

  addSeminar() {
    this.$v.$touch();
    if (this.$v.$invalid || this.nameErrors.length > 0) {
      const msg = this.$t("forms.invalidform").toString();
      this.displaySnackbar(msg);
      return;
    }

    this.addDialog = false;
    try {
      this.createSeminar({
        name: this.name.trim(),
        description: this.description.trim(),
        logo: this.logo,
      });
    } catch (error) {
      this.error = error;
      const msg = "Seminar creation failed";
      this.displaySnackbar(msg);
    }
  }

  get hasError() {
    return !!this.error || this.nameErrors.length > 0;
  }
}
