import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"d-flex"},[_c('div',{staticClass:"flex-1 mr-4"},[_vm._v(_vm._s(_vm.$t("invitations.title")))]),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.getInvitations}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)],1),_c(VCardText,[(_vm.allInvitations.length > 0)?_c('div',{staticClass:"mb-4 d-flex align-center"},[_c(VSpacer),_c('div',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$t("invitations.type")))]),_c(VBtn,{staticClass:"mr-2",attrs:{"small":"","elevation":"0","color":_vm.type === 'all' ? 'primary' : ''},on:{"click":function($event){_vm.type = 'all'}}},[_c('span',[_vm._v(_vm._s(_vm.$t("invitations.all")))])]),_c(VBtn,{staticClass:"mr-2",attrs:{"small":"","elevation":"0","color":_vm.type === 'active' ? 'primary' : ''},on:{"click":function($event){_vm.type = 'active'}}},[_c('span',[_vm._v(_vm._s(_vm.$t("invitations.active")))])]),_c(VBtn,{attrs:{"small":"","elevation":"0","color":_vm.type === 'inactive' ? 'primary' : ''},on:{"click":function($event){_vm.type = 'inactive'}}},[_c('span',[_vm._v(_vm._s(_vm.$t("invitations.inactive")))])])],1):_vm._e(),_c(VList,_vm._l((_vm.invitations),function(inv,i){return _c(VListItem,{key:i,staticStyle:{"position":"relative"},on:{"click":function () {}}},[_c(VOverlay,{staticStyle:{"position":"absolute"},attrs:{"value":_vm.getLoading(inv)}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"32"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.getTitle(inv)))]),_c(VListItemSubtitle,[_c('span',[_vm._v(_vm._s(_vm._f("formatwithtime")(inv.createdAt)))]),(_vm.type === 'all')?_c('span',{staticClass:"mx-1"},[_vm._v("·")]):_vm._e(),(_vm.type === 'all')?_c('span',[_vm._v(" "+_vm._s(inv.active ? "Active" : "Inactive")+" ")]):_vm._e()])],1),_c(VListItemAction,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.handleAction(inv, true)}}},on),[_c(VIcon,[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("invitations.accept")))])])],1),_c('div',{staticClass:"mx-1"}),_c(VListItemAction,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.handleAction(inv)}}},on),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("invitations.decline")))])])],1)],1)}),1),(!_vm.invitations.length)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("invitations.noInvitations"))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }