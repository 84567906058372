import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VBtn,{attrs:{"elevation":"0","color":"primary","loading":_vm.adding,"disabled":_vm.adding},on:{"click":function($event){_vm.addDialog = true}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t("seminars.admin.addBtn")))])],1),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c(VForm,{on:{"submit":_vm.addSeminar}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("seminars.admin.addTitle")))]),_c(VCardText,[_c('p',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t("seminars.admin.addText")))]),_c(VTextField,{attrs:{"outlined":"","data-automation-id":"create-seminar-name-input","label":_vm.$t('seminars.name'),"error-messages":_vm.nameErrors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VTextField,{attrs:{"outlined":"","data-automation-id":"create-seminar-desc-input","label":_vm.$t('seminars.description')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('h3',[_vm._v("Seminar logo")]),_c(VImg,{staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.preview,"width":"100%"}}),_c(VFileInput,{attrs:{"show-size":"","accept":".png, .jpg, .jpeg","data-automation-id":"create-seminar-logo-input"},on:{"change":_vm.handleFileChanged},model:{value:(_vm.logo),callback:function ($$v) {_vm.logo=$$v},expression:"logo"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"0","data-automation-id":"create-seminar-cancel"},on:{"click":function($event){_vm.addDialog = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])]),_c(VBtn,{attrs:{"disabled":_vm.hasError,"elevation":"0","color":"primary","data-automation-id":"create-seminar-confirm"},on:{"click":_vm.addSeminar}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("global.confirm"))+" ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }